import React from "react";
import Lottie, { Options } from "react-lottie";
import botao from '../assets/BOTAO.json';
import "./Button.css";

const Button: React.FC = () => {
    const handleClick = () => {
        alert("Inscrição realizada com sucesso!");
    };

    const defaultOptions: Options = {
        loop: true,
        autoplay: true,
        animationData: botao,
    };

    return (
        <div id="button-inscrever">
            <button onClick={handleClick}>
                {/* SVG dentro do botão */}
                <Lottie options={defaultOptions} />
            </button>
        </div>
    );
};

export default Button;
