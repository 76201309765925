import React from 'react';
import './Section7.css';
import Lottie, { Options } from "react-lottie";
import botao from '../assets/BOTAO.json';


interface Props {
    title: string;
    description: string;
}
const handleClick = () => {
    alert("Inscrição realizada com sucesso!");
};

const defaultOptions: Options = {
    loop: true,
    autoplay: true,
    animationData: botao,
};
const Section7: React.FC = () => {
    return (
        <div className="container-section7 d-flex justify-content-end w-70">
            <div className='container-garantia'>
                <h1 className='title-garantia'>Garantia Incondicional</h1>
                <h5 className='marrom-text'>
                    Você tem, por lei, o direito de testar o produto durante 7 dias. Se dentro desse
                    período você achar que o curso não é pra você, basta enviar um e-mail para <strong>sac@gregpersonal.com</strong> para solicitar o reembolso.
                </h5>
                <h5 className='marrom-text'>
                    Não precisa se justificar e ainda continuaremos amigos
                    (mas dizem que quem pede reembolso não vai pro céu)
                </h5>
                <div>
                    <button className="btn-2" onClick={handleClick}>
                        {/* SVG dentro do botão */}
                        <Lottie options={defaultOptions} />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Section7;