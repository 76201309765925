import './BannerInicial.css';
import logo from '../assets/LOGOBMSSVG.svg';
import perfil from '../assets/perfil.png';

import React from 'react';
import Button from './Button';
import Lottie from 'react-lottie';




function BannerInicial() {
    return (
        <React.Fragment>
            <div className="container-geral">
            
                <div className="container-banner">
                <div className='bg'></div>
                    <div className="container-left">
                        <img src={logo} alt="logo" id='logo-banner' />

                        <div id="text-1">
                            <p>Potencialize sua jornada de transformação com BMS</p>
                        </div>
                        <div id="text-2">
                            <p>Transforme seu corpo em tempo recorde, garantindo resultados duradouros e uma nova confiança.</p>
                        </div>

                        <div id="button-banner">
                            <Button></Button>
                        </div>
                        <div className='animacoes'>
                            <Lottie options={{ loop: true, autoplay: true, animationData: require('../assets/ANIMATION1.json') }} />
                        </div>
                    </div>
                    <div className="container-right">
                        <img src={perfil} alt="perfil" id='foto-perfil' />
                    </div>

                </div>
               
            </div>
        </React.Fragment>
    );
}

export default BannerInicial;