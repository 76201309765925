import './Section5.css'
import React from 'react'
import banner1 from '../assets/100.png'
import banner2 from '../assets/Alainy 1.png'
import banner3 from '../assets/Adriano 1.png'
import banner4 from '../assets/101.png'


const Section5: React.FC = () => {
    return (
        <div className="container_section5">
            <div className="banner_section5">
                <img src={banner1} alt="" />
            </div>
            <div className="banner_section5">
                <img src={banner2} alt="" />
            </div>
            <div className="banner_section5">
                <img src={banner3} alt="" />
            </div>
            <div className="banner_section5">
                <img src={banner4} alt="" />
            </div>
        </div>
    );
}

export default Section5;