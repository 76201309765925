import React from 'react';
import './Section6.css';
import imagem from '../assets/imageVoce.svg';
import verificado from '../assets/Vector.svg';
import Lottie, { Options } from "react-lottie";
import botao from '../assets/BOTAO.json';


interface Props {
    title: string;
    description: string;
}
const handleClick = () => {
    alert("Inscrição realizada com sucesso!");
};

const defaultOptions: Options = {
    loop: true,
    autoplay: true,
    animationData: botao,
};

const Section6: React.FC = () => {
    return (
        <div className='container_section6'>
            <div className="card_section6">
                <h1 className='vejaOqueVoce mt-5'>Veja o que você vai receber</h1>
                <img  className="imgSection6" src={imagem} alt="plataforma" />
                <div className="containerlist d-flex flex-column justify-content-center mt-5">
                    <div className="itemList d-flex align-items-baseline justify-content-center">
                        <img className="me-2" src={verificado} alt="" />
                        <p className="textList" style={{ color: "#fff" }}>Curso BMS Completo R$ 997,00</p>
                    </div>
                    <div className="itemList d-flex align-items-baseline justify-content-center">
                        <img className="me-2" src={verificado} alt="" />
                        <p className="textList" style={{ color: "#fff" }}>Bônus 1: Curso Demartofuncional R$ 497,00</p>
                    </div>
                    <div className="itemList d-flex align-items-baseline justify-content-center">
                        <img className="me-2" src={verificado} alt="" />
                        <p className="textList" style={{ color: "#fff" }}>Bônus 2: Curso identidade da Mulher R$ 997,00</p>
                    </div>
                    <div className="itemList d-flex align-items-baseline justify-content-center">
                        <img className="me-2" src={verificado} alt="" />
                        <p className="textList" style={{ color: "#fff" }}>Bônus 3: Curso Vida Financeira R$ 997,00</p>
                    </div>
                    <div className="itemList d-flex align-items-baseline justify-content-center">
                        <img className="me-2" src={verificado} alt="" />
                        <p className="textList" style={{ color: "#fff" }}>Bônus 4: Curso Conecte consigo mesma R$ 497,00</p>
                    </div>
                    <div className="itemList d-flex align-items-baseline justify-content-center">
                        <img className="me-2" src={verificado} alt="" />
                        <p className="textList" style={{ color: "#fff" }}>Bônus 5: Comunidade e Plataforma Exclusiva R$ 497,00</p>
                    </div>
                    <div className="itemList d-flex align-items-baseline justify-content-center">
                        <img className="me-2" src={verificado} alt="" />
                        <p className="textList" style={{ color: "#fff" }}>Bônus 6: Botão de Emergência R$ 97,00</p>
                    </div>
                    <div className="itemList d-flex align-items-baseline justify-content-center">
                        <img className="me-2" src={verificado} alt="" />
                        <p className="textList" style={{ color: "#fff" }}>Bônus 7: Central de Ajuda 997,00</p>
                    </div>
                    <div className="itemList d-flex align-items-baseline justify-content-center">
                        <img className="me-2" src={verificado} alt="" />
                        <p className="textList" style={{ color: "#fff" }}>Bônus 8: Comunidade e Plataforma Exclusiva 997,00</p>
                    </div>
                    <div className="itemList d-flex align-items-baseline justify-content-center">
                        <img className="me-2" src={verificado} alt="" />
                        <p className="textList" style={{ color: "#fff" }}>Bônus 9: Ebook's R$ 997,00</p>
                    </div>
                    <div className="itemList d-flex align-items-baseline justify-content-center mb-3">
                        <img className="me-2" src={verificado} alt="" />
                        <p className="textList" style={{ color: "#fff" }}>Bônus 10: Blog exclusivo com artigos e dicas R$ 997,00</p>
                    </div>

                    <div className=" total-price mt-5 d-flex flex-column alignitems-baseline justify-content-center">
                        <h1 className='total-price'>Total: R$ 9.358,00</h1>
                        <h1 className='por-apenas'>Por Apenas</h1>
                        <div className='  d-flex flex-row align-content-start justify-content-center'>
                            <h4 className='por12x'>12 x de</h4>
                            <h1 className='preco'>99.70</h1>
                        </div>

                    </div>
                    <div  >
                        <button className="btn-2" onClick={handleClick}>
                            {/* SVG dentro do botão */}
                            <Lottie options={defaultOptions} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Section6;
