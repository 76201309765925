
import React from 'react';
import './Section3.css';
import antesxDepois3 from '../assets/ANTESxDEPOIS-11-1024x1024.png3.png';
import antesxDepois2 from '../assets/ANTESxDEPOIS-18-1024x1024.png2.png';
import antesxDepois1 from '../assets/ANTESxDEPOIS-19-1024x1024.pngantesEDepois1.png';
import antesxDepois4 from '../assets/ANTESxDEPOIS-4-1024x1024.png4.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


import { EffectCoverflow, Pagination, Autoplay } from 'swiper/modules';

const Section3: React.FC = () => {
    return (
        <div className='section3'>
            <div className="header">
                <p>O que as alunas falam</p>
            </div>
            <div className="container_conteudo">
                <div className="antesEDepois">
                    <img src={antesxDepois1} alt="" className="img_container3" />

                </div>
                <div className="antesEDepois">
                    <img src={antesxDepois2} alt="" className="img_container3" />
                </div>
                <div className="antesEDepois">
                    <img src={antesxDepois3} alt="" className="img_container3" />

                </div>
                <div className="antesEDepois">
                    <img src={antesxDepois4} alt="" className="img_container3" />
                </div>
            </div>
            <Swiper
        slidesPerView={3}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>Slide 1</SwiperSlide>
        <SwiperSlide>Slide 2</SwiperSlide>
        <SwiperSlide>Slide 3</SwiperSlide>
        <SwiperSlide>Slide 4</SwiperSlide>
        <SwiperSlide>Slide 5</SwiperSlide>
        <SwiperSlide>Slide 6</SwiperSlide>
        <SwiperSlide>Slide 7</SwiperSlide>
        <SwiperSlide>Slide 8</SwiperSlide>
        <SwiperSlide>Slide 9</SwiperSlide>
      </Swiper>
        </div>
    );
};

export default Section3;
