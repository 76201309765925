import React from 'react';
import iconVerificado from '../assets/SVGicon-verificado.svg';
import img2 from '../assets/imagem2.svg';
import './Section2.css';

const Section2: React.FC = () => {
    return (
        <div className="section2">
        <div className="container-left">
            <div id="heading-section2">
                <p>Esse curso é pra você caso:</p>
            </div>
            <div className="container-verificado">
                <div className='verificado'>
                    <img src={iconVerificado} alt="icone de verificado" id='icon-verificado' />
                    <p>Busca resultados rápidos e duradouros.</p>
                </div>
                <div className='verificado'>
                    <img src={iconVerificado} alt="icone de verificado" id='icon-verificado' />
                    <p>Deseja uma abordagem eficaz e comprovada.</p>
                </div>
                <div className='verificado'>
                    <img src={iconVerificado} alt="icone de verificado" id='icon-verificado' />
                    <p>Deseja valoriza sua confiança e autoestima.</p>
                </div>
                <div className='verificado'>
                    <img src={iconVerificado} alt="icone de verificado" id='icon-verificado' />
                    <p>Procura praticidade e facilidade..</p>
                </div>
                <div className='verificado'>
                    <img src={iconVerificado} alt="icone de verificado" id='icon-verificado' />
                    <p>Deseja apoio e orientação especializada.</p>
                </div>
            </div>

        </div>
        <div className="container-right">
            <img src={img2} alt="img2" id='img2' />
        </div>
    </div>
    );
};

export default Section2;
