import React from 'react';
import './App.css';
import Lottie from 'react-lottie';
import data from './assets/FITA.json'
import BannerInicial from './components/BannerInicial';
import Section2 from './components/Section2';
import Section3 from './components/Section3';
import Section4 from './components/Section4';
import Section5 from './components/Section5';
import Section6 from './components/Section6';
import Section7 from './components/Section7';


function App() {

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: data, 
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };



  return (
    <div className="App">

      
        
       
       



          <BannerInicial></BannerInicial>
          <Section2></Section2>
          <Section3></Section3>
          <Section4></Section4>
          <Section5></Section5>
          <Section6></Section6>
          <Section7></Section7>
       
          



    </div>
  );
}

export default App;
