import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import conteudo1 from '../assets/Shape.png';
import conteudo2 from '../assets/Shape-2.png';
import conteudo3 from '../assets/Shape-3.png';
import conteudo4 from '../assets/CAPAS_GREG_5 1.png';
import conteudo5 from '../assets/CAPAS_GREG_6 1.png';

import svg from '../assets/SVG_button_conteudo.png';


import './Section4.css';
import { Pagination } from 'swiper/modules';

const Section4: React.FC = () => {
  return (
    <div className='container_section4'>

      <div className="heading_section4">

        <p>Conteúdo do curso</p>
        {/* <div className="container_conteudo">
          <div className="conteudo">
            <img src={conteudo1} alt="" className="img_container4" />
            <button className="button_conteudo">
              <img src={svg} alt="" />
              Saiba mais
            </button>
          </div>
          <div className="conteudo">
            <img src={conteudo2} alt="" className="img_container4" />
            <button className="button_conteudo">
              <img src={svg} alt="" />
              Saiba mais
            </button>
          </div>
          <div className="conteudo">
            <img src={conteudo3} alt="" className="img_container4" />
            <button className="button_conteudo">
              <img src={svg} alt="" />
              Saiba mais
            </button>
          </div>
          <div className="conteudo">
            <img src={conteudo4} alt="" className="img_container4" />
            <button className="button_conteudo">
              <img src={svg} alt="" />
              Saiba mais
            </button>
          </div>
          <div className="conteudo">
            <img src={conteudo5} alt="" className="img_container4" />
            <button className="button_conteudo">
              <img src={svg} alt="" />
              Saiba mais
            </button>
          </div>
        </div> */}

      </div>
      <Swiper
        slidesPerView={2}
        spaceBetween={3}
        loop={true}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper carrosselMD"
      >
        <SwiperSlide><div className="conteudo">
            <img src={conteudo1} alt="" className="img_container4" />
            <button className="button_conteudo">
              <img src={svg} alt="" />
              Saiba mais
            </button>
          </div></SwiperSlide>
        <SwiperSlide><div className="conteudo">
            <img src={conteudo2} alt="" className="img_container4" />
            <button className="button_conteudo">
              <img src={svg} alt="" />
              Saiba mais
            </button>
          </div></SwiperSlide>
        <SwiperSlide><div className="conteudo">
            <img src={conteudo3} alt="" className="img_container4" />
            <button className="button_conteudo">
              <img src={svg} alt="" />
              Saiba mais
            </button>
          </div></SwiperSlide>
        <SwiperSlide><div className="conteudo">
            <img src={conteudo4} alt="" className="img_container4" />
            <button className="button_conteudo">
              <img src={svg} alt="" />
              Saiba mais
            </button>
          </div></SwiperSlide>
        <SwiperSlide>
          <div className="conteudo">
            <img src={conteudo5} alt="" className="img_container4" />
            <button className="button_conteudo">
              <img src={svg} alt="" />
              Saiba mais
            </button>
            </div>
            </SwiperSlide>
      </Swiper><Swiper
        slidesPerView={1}
        spaceBetween={3}
        loop={true}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper carrosselSM"
      >
        <SwiperSlide><div className="conteudo">
            <img src={conteudo1} alt="" className="img_container4" />
            <button className="button_conteudo">
              <img src={svg} alt="" />
              Saiba mais
            </button>
          </div></SwiperSlide>
        <SwiperSlide><div className="conteudo">
            <img src={conteudo2} alt="" className="img_container4" />
            <button className="button_conteudo">
              <img src={svg} alt="" />
              Saiba mais
            </button>
          </div></SwiperSlide>
        <SwiperSlide><div className="conteudo">
            <img src={conteudo3} alt="" className="img_container4" />
            <button className="button_conteudo">
              <img src={svg} alt="" />
              Saiba mais
            </button>
          </div></SwiperSlide>
        <SwiperSlide><div className="conteudo">
            <img src={conteudo4} alt="" className="img_container4" />
            <button className="button_conteudo">
              <img src={svg} alt="" />
              Saiba mais
            </button>
          </div></SwiperSlide>
        <SwiperSlide>
          <div className="conteudo">
            <img src={conteudo5} alt="" className="img_container4" />
            <button className="button_conteudo">
              <img src={svg} alt="" />
              Saiba mais
            </button>
            </div>
            </SwiperSlide>
      </Swiper>
      <Swiper
        slidesPerView={4}
        spaceBetween={3}
        loop={true}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper carrosselXL"
      >
        <SwiperSlide><div className="conteudo">
            <img src={conteudo1} alt="" className="img_container4" />
            <button className="button_conteudo">
              <img src={svg} alt="" />
              Saiba mais
            </button>
          </div></SwiperSlide>
        <SwiperSlide><div className="conteudo">
            <img src={conteudo2} alt="" className="img_container4" />
            <button className="button_conteudo">
              <img src={svg} alt="" />
              Saiba mais
            </button>
          </div></SwiperSlide>
        <SwiperSlide><div className="conteudo">
            <img src={conteudo3} alt="" className="img_container4" />
            <button className="button_conteudo">
              <img src={svg} alt="" />
              Saiba mais
            </button>
          </div></SwiperSlide>
        <SwiperSlide><div className="conteudo">
            <img src={conteudo4} alt="" className="img_container4" />
            <button className="button_conteudo">
              <img src={svg} alt="" />
              Saiba mais
            </button>
          </div></SwiperSlide>
        <SwiperSlide>
          <div className="conteudo">
            <img src={conteudo5} alt="" className="img_container4" />
            <button className="button_conteudo">
              <img src={svg} alt="" />
              Saiba mais
            </button>
            </div>
            </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Section4;